import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  PLATFORM_ID,
  provideExperimentalZonelessChangeDetection,
  TransferState
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '@shared/misc/custom-missing-translation-handler';
import { ResourceService } from '@shared/services/resource.service';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ConfigurationService } from '@shared/services/configuration.service';
import { TranslateHttpLoaderService } from '@shared/services/translate-http-loader.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ConfirmationService } from 'primeng/api';
import { LocalizeRoutePipe } from '@shared/pipes/localize-route.pipe';
import { PlaceholderReplacePipe } from '@shared/pipes/placeholder-replace.pipe';
import { DatePipe } from '@angular/common';
import { CurrencyDisplayPipe } from '@shared/pipes/currency-display.pipe';
import { HttpInterceptorService } from '@shared/services/http-interceptor.service';
import { BINARY_ENCRYPTION } from '@shared/services/binary-encryption';
import { NaclBinaryEncryption } from '@shared/services/nacl-binary-encryption';
import { GlobalErrorHandler } from '@shared/services/global-error-handler.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BootstrapGuard } from './pages/bootstrap-guard.service';
import * as Sentry from '@sentry/angular';


import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/cs';

import '@angular/common/locales/global/es';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/hu';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/sk';

export const appConfig: ApplicationConfig =
  {
    providers: [
      provideAnimations(),
      provideHttpClient(withFetch(), withInterceptorsFromDi()),
      provideRouter([
        {
          path: '',
          loadChildren: () => import('./pages/pages.routes').then((m) => m.PagesRoutes),
          canActivate: [BootstrapGuard]
        }
      ]),
      // provideClientHydration(withEventReplay()), // use hydration with NG 18 event replay),
      provideExperimentalZonelessChangeDetection(),
      importProvidersFrom(
        OAuthModule.forRoot(),
        ToastrModule.forRoot({ positionClass: 'toast-bottom-center' }),
        TranslateModule.forRoot({
          missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: CustomMissingTranslationHandler,
            deps: [ResourceService]
          },
          loader: {
            provide: TranslateLoader,
            useFactory: (http: HttpClient, resourceService: ResourceService, configService: ConfigurationService, platformId: string, transferState: TransferState) => new TranslateHttpLoaderService(http, resourceService, configService, platformId, transferState),
            deps: [HttpClient, ResourceService, ConfigurationService, PLATFORM_ID, TransferState]
          }
        }),
        SweetAlert2Module.forRoot({
          provideSwal,
          dismissOnDestroy: false
        })
      ),
      ConfirmationService,
      LocalizeRoutePipe,
      PlaceholderReplacePipe,
      DatePipe,
      CurrencyDisplayPipe,
      /* TODO user an AppInitializerService as we are already using in the portal-client (tackle that when migrating to V3 Monorepo
            {
              provide: APP_INITIALIZER,
              useFactory: (envService: EnvService) => () => envService.getEnvSettings(),
              deps: [EnvService],
              multi: true
            },*/
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorService,
        multi: true
      },
      {
        provide: BINARY_ENCRYPTION,
        useClass: NaclBinaryEncryption
      },
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler
      },
      {
        provide: Sentry.TraceService,
        deps: [Router]
      },
    ]
  };


async function provideSwal(): Promise<any> {
  const { default: Swal } = await import('sweetalert2');
  return Swal.mixin({
    showCloseButton: true,
    buttonsStyling: false,
    customClass: { confirmButton: 'btn btn-primary btn-fill', cancelButton: 'btn btn-outline-secondary ms-3' }
  });
}
