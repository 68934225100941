import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneratedLocalizationService } from '@shared/generated/services';
import { CustomLocalizationTarget } from '@shared/generated/models';
import { ILocalizations } from '@shared/models/localization-interfaces';

@Injectable({ providedIn: 'root' })
export class ResourceService {

  constructor(private http: HttpClient) {
  }

  // TODO move to custom-translate-http-loader?
  public getTranslation(culture: string): Observable<Partial<ILocalizations>> {
    const path = GeneratedLocalizationService.GetLocalizationsPath.split('/');
    const typeKey = path[path.length - 1];
    return this.http.get(GeneratedLocalizationService.GetLocalizationsPath.replace(typeKey, CustomLocalizationTarget.Frontend) + `?rnd=${Math.random()}`, { headers: { 'Accept-Language': culture } });
  }
}
