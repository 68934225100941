import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ResourceService } from '@shared/services/resource.service';
import { catchError, map, tap } from 'rxjs/operators';
import { mergeDeep, observableWithFallbacks } from 'inova-shared';
import { ILocalizations } from '@shared/models/localization-interfaces';
import { ConfigurationService } from '@shared/services/configuration.service';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, StateKey, TransferState } from '@angular/core';

export class TranslateHttpLoaderService extends TranslateHttpLoader {

  public readonly stateKeyTranslations: StateKey<ILocalizations> = makeStateKey('translation');

  constructor(private httpClient: HttpClient,
              private resourceService: ResourceService,
              private configService: ConfigurationService,
              private platformId: string,
              private transferState: TransferState) {
    super(httpClient);
  }

  public override getTranslation(lang: string): Observable<ILocalizations> {
    const cultureWithFallbacks = [lang, ...(this.configService.configuration!.siteCultures.find((c) => c.cultureName === lang)?.fallbackCultureNames ?? [])];
    let request = forkJoin([
      observableWithFallbacks<ILocalizations>(cultureWithFallbacks.map((c) => this.httpClient.get(`/assets/translations/${c.toLowerCase()}.json?rnd=${Math.random()}`, { responseType: 'text' })
        .pipe(map((t) => {
          const jsonString = t.trim().replaceAll('\n', '');
          return JSON.parse(jsonString);
        })))),
      observableWithFallbacks<Partial<ILocalizations>>(cultureWithFallbacks.map((c) => this.resourceService.getTranslation(c)))
    ])
      .pipe(catchError((e) => {
        console.error('could not load any of these languages:', cultureWithFallbacks);
        throw e;
      }))
      .pipe(map((translations) => {
        const result = mergeDeep(translations[0], translations[1]) as ILocalizations;
        return lang.startsWith('de-') && this.configService.configuration!.replaceGermanLotTerms
          ? this.losToLotsReplacer(result as unknown as JsonObject) as unknown as ILocalizations
          : result;
      }))
      .pipe(tap((result) => isPlatformServer(this.platformId) && this.transferState.set(this.stateKeyTranslations, result)));
    if (this.transferState.hasKey(this.stateKeyTranslations)) {
      request = of(this.transferState.get(this.stateKeyTranslations, null)!).pipe(tap(() => this.transferState.remove(this.stateKeyTranslations)));
    }
    return request;
  }

  private losToLotsReplacer(obj: JsonObject): JsonObject {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = (obj[key] as string).replace(/Losen/g, 'Lots').replace(/Loses/g, 'Lots').replace(/Lose/g, 'Lots').replace(/Los/g, 'Lot');
      } else if (typeof obj[key] === 'object') {
        obj[key] = this.losToLotsReplacer(obj[key] as JsonObject);
      }
    }
    return obj;
  }
}

interface JsonObject {
  [key: string]: string | JsonObject
}
