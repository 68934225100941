import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {

  constructor() {
    super({ showDialog: false });
  }

  public override handleError(error: any): void {
    super.handleError(error);
    if (error?.message?.includes('ChunkLoadError: Loading chunk')) {
      location.reload();
    }
  }
}
