import { enableProdMode } from '@angular/core';


import { environment } from '@environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';


if (environment.production) {
  enableProdMode();

  // disable any console.log debugging statements in production mode
  console.log = () => {
  };
  console.warn = () => {
  };
  console.debug = () => {
  };
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
