import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ResourceService } from '@shared/services/resource.service';

// TODO remove
export class CustomMissingTranslationHandler implements MissingTranslationHandler {

  constructor(private ressourceService: ResourceService) {
  }

  public handle(params: MissingTranslationHandlerParams): string {
    /*     if (!this.ressourceService.reportedMissingKeys.includes(params.key)) {
          this.ressourceService.addMissingTranslationKey(params.translateService.currentLang, params.key).subscribe();
        } */
    return '**' + params.key + '**';
  }

}
