import { box, randomBytes, secretbox } from 'tweetnacl';
import { decodeBase64, encodeBase64 } from 'tweetnacl-util';
import { combineLatest, from, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncryptionKeyPair } from '@shared/models/encryption-key-pair';
import { BinaryEncryption } from '@shared/services/binary-encryption';

export class NaclBinaryEncryption implements BinaryEncryption {
  public createKeyPair(): EncryptionKeyPair {
    const keyPair = box.keyPair();
    return {publicKey: keyPair.publicKey, privateKey: keyPair.secretKey};
  }

  public decrypt(encryptedData: Uint8Array, encryptedKeyWithPublicKey: string, nonce: string, key: Uint8Array): Uint8Array {
    const nonceUint8Array = decodeBase64(nonce);
    const encryptedKeyWithPublicKeyUint8Array = decodeBase64(encryptedKeyWithPublicKey);
    const encryptedKeyLength = encryptedKeyWithPublicKeyUint8Array.length - secretbox.keyLength;
    const encryptedKey = encryptedKeyWithPublicKeyUint8Array.slice(0, encryptedKeyLength);
    const publicKey = encryptedKeyWithPublicKeyUint8Array.slice(encryptedKeyLength, secretbox.keyLength);
    const decryptedKey = box.open(encryptedKey, nonceUint8Array, publicKey, key);
    if (!decryptedKey) {
      throw new Error('Unable to decrypt key');
    }
    const decryptedData = secretbox.open(encryptedData, nonceUint8Array, decryptedKey);
    if (!decryptedData) {
      throw new Error('Unable to decrypt data');
    }
    return decryptedData;
  }

  public encrypt(file: File, publicKey: Uint8Array): Observable<{ nonce: string, encryptedData: Uint8Array, encryptedKeyWithPublicKey: string, sha1: string }> {
    const nonce = randomBytes(box.nonceLength);
    const symKey = randomBytes(secretbox.keyLength);
    // const documentEncryptionKeyPair = box.keyPair();
    const clientKeyPair = box.keyPair();
    return from(this.readFileContent(file)).pipe(
      switchMap((filecontent) =>
        combineLatest(
          of(filecontent),
          from(crypto.subtle.digest('SHA-1', filecontent))
        )
      ),
      map((data) => {
        const sha1Format = encodeBase64(new Uint8Array(data[1]));
        const encryptedKey = box(symKey, nonce, publicKey, clientKeyPair.secretKey);
        const encryptedKeyWithPublicKey = new Uint8Array(encryptedKey.length + clientKeyPair.publicKey.length);
        encryptedKeyWithPublicKey.set(encryptedKey);
        encryptedKeyWithPublicKey.set(clientKeyPair.publicKey, encryptedKey.length);
        const encryptedData = secretbox(data[0], nonce, symKey);
        return {nonce: encodeBase64(nonce), encryptedData: encryptedData, encryptedKeyWithPublicKey: encodeBase64(encryptedKeyWithPublicKey), sha1: sha1Format};
      }));
  }

  private readFileContent(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(new Uint8Array(<ArrayBufferLike>reader.result));
      };

      reader.onerror = () => {
        reject(new Error('Error reading the file.'));
      };
      reader.readAsArrayBuffer(file);
    });
  }

}
